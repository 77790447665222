<template>
  <div class="app-container">
    排行榜
    <el-table :data="tableData" border>
      <el-table-column prop="date" label="排名" min-width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="战队" min-width="120" align="center">
        <template slot-scope="scope">
          {{ JSON.parse(scope.row.team_snap).name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="积分"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.points }}
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="净胜分"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.net_points }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      list: [],
      listQuery: {
        page: 1,
        limit: 10,
        match_id: null,
      },
      tableData: [],
    };
  },
  created() {
    this.listQuery.match_id = this.$route.query.id;
    this.listQuery.match_system_id = this.$route.query.match_system_id;
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/match/rankingList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.tableData = response.data;
        this.listLoading = false;
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
